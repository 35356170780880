import React, { useRef} from "react";

const SubmitForm = () => {

  return (
    <div className="container">
  <h1 className="text-center square bg-white rounded" >Please Fill Information</h1>
  <form target="_blank" action="https://formsubmit.co/your@email.com" method="POST">
    <div className="form-group">
      <div className="form-row ">
        <div className="col">
          <input type="text" name="name" className="form-control" placeholder="Full Name" required/>
        </div>
        <div className="col">
          <input type="email" name="email" className="form-control" placeholder="Email Address" required/>
        </div>
      </div>
    </div>
    <div className="form-group text-center">

      <label>
        <input type="radio" value="Buy" checked={true}/>
        {/* <Text>{`Buy \t`}</Text> */}
        {/* <p>Buy</p> */}
      </label>

      <label>
        <input type="radio" value="Sell" />
        {/* <p>Sell</p> */}
      </label>

      <label>
        <input type="radio" value="new" />
        {/* <p>Rent</p> */}
      </label>
 
    </div>
    <div className="form-group">
      <textarea placeholder="Your Message" className="form-control" name="message" rows="10" required></textarea>
    </div>
    <button type="submit" className="btn btn-lg btn-dark btn-block">Submit Form</button>
  </form>
</div>
  );
};

export default SubmitForm;
