import React from "react";
import NotFoundContent from "../components/Layout/NotFound";

const NotFound = () => {
  return (
    <div>
      <NotFoundContent />
    </div>
  );
};

export default NotFound;
