import React, { useEffect, useRef } from "react";
import Footer from "../components/Layout/Footer";
import PropertyListing from "../components/Layout/PropertyListing";
import FAQs from "../components/Layout/FAQs";
import { FiLayers } from "react-icons/fi";
import SubmitForm from "../components/Forms/SubmitForm";

const FillInfoPage = () => {
  // const divRef = useRef();

  // useEffect(() => {
  //   divRef.current.scrollIntoView({ behavior: "smooth" });
  // });
  return (
    <div className="bg-silver pt-20" >
      {/* <FAQs /> */}
      <SubmitForm/>
      {/* <Footer /> */}
    </div>
  );
};

export default FillInfoPage;
