import React, { Fragment } from "react";
import PurposeItem from "../Data/PurposeItem";

import { HiOutlineHome } from "react-icons/hi";
import { FiKey } from "react-icons/fi";
import { FaHandshake } from "react-icons/fa";

const Purpose = () => {
  const purposeData = [
    {
      id: "p1",
      icon: <HiOutlineHome />,
      title: "Buy A Home",
      description: "Settling in a new area.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSck-PsJ-CDvTDBv3xFU4_7n8DuidKB20YmX3zz3hLuKaSkPzw/viewform?usp=sf_link"
    },
    {
      id: "p2",
      icon: <FiKey />,
      title: "Rent A Home",
      description: "Looking for a short/long term rental.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSck-PsJ-CDvTDBv3xFU4_7n8DuidKB20YmX3zz3hLuKaSkPzw/viewform?usp=sf_link"
    },
    {
      id: "p3",
      icon: <FaHandshake />,
      title: "Sell A Home",
      description: "Time to start a new chapter of your life.",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSck-PsJ-CDvTDBv3xFU4_7n8DuidKB20YmX3zz3hLuKaSkPzw/viewform?usp=sf_link"

    },
  ];

  const mappedList = purposeData.map((purpose) => {
    return (
      <PurposeItem
        key={purpose.id}
        id={purpose.id}
        icon={purpose.icon}
        title={purpose.title}
        description={purpose.description}
        link={purpose.link}
      />
    );
  });

  return (
    <Fragment>
      <section className="mx-auto bg-silverLite px-10 md:px-16 lg:px-20 py-20 pt-20 md:py-16">
        <div className="px-auto lg:px-32">
          <h1 className="font-Poppins font-bold text-3xl text-center mb-4">
            Our <span className="text-blue">Focus</span>
          </h1>
          <p className="text-center text-ash">
            Creatively solve peoples real estate problems through our three mains focuses
          </p>
        </div>
        <ul className="flex center flex-col justify-center lg:flex-row my-6" >{mappedList}</ul>
      </section>
    </Fragment>
  );
};

export default Purpose;
